import React, { useContext, useMemo } from "react";
import { BasicData } from '@App';
import { Trans } from "@lingui/macro";

const NavBarDropDownDestination = ({ id, destination_id, stopIndex, active, navigateTo }) => {
    const { destinations } = useContext(BasicData);

    const isActive = useMemo(() => {
        return active === `destination-${id}-${destination_id}${stopIndex >= 0 ? `-${stopIndex}` : ''}`;
    }, [id, destination_id, stopIndex, active]);

    return useMemo(() => (
        <div onClick={navigateTo(`destination-${id}-${destination_id}${stopIndex >= 0 ? `-${stopIndex}` : ''}`)} className={`nav-bar-dropdown-leg-section destination-section${isActive ? ' active' : ''}`}>
            <div className="nav-bar-dropdown-leg-section-heading">
                <div className="circle" />
                <p><Trans>Destination</Trans></p>
            </div>
            <div className="nav-bar-dropdown-leg-section-item">
                <p>{destinations[destination_id]?.name}</p>
            </div>
        </div>
    ), [id, destination_id, stopIndex, isActive, destinations, navigateTo])
}

export default NavBarDropDownDestination;