/* eslint-disable */
import { useRef, useLayoutEffect } from "react";


function getScrollPosition({element, useWindow}) { // element optional, but will return scroll position of element, not page. Might be useful in future
    if (!window) {
        return {x: 0, y: 0}
    }

    const target = element ? element.current : document.body;
    const position = target.getBoundingClientRect();

    return useWindow ? {x: window.scrollX, y: window.scrollY} : {x: position.left, y: position.top}
}

export function useScrollPosition(effect, deps, element, useWindow) {
    const position = useRef(getScrollPosition({ useWindow }))

    const callBack = () => {
        const currPos = getScrollPosition({element, useWindow})
        effect({prevPos: position.current, currPos})
        position.current = currPos;
    }

    useLayoutEffect(() => {
        const handleScroll = () => {
            // doing it this way for possible future expansion, including debounce if needed
            callBack()
        }

        window.addEventListener('scroll', handleScroll)

        return () => window.removeEventListener('scroll', handleScroll)
    }, deps)
}
