import React, { useEffect, useState, createContext, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import ErrorPage from '@components/error-page';
import CoverPage from '@components/cover-page';
import SpinnerComponent from '@components/spinner';

import LayoutComponent from "@components/layout";

import { I18nProvider } from '@lingui/react';
import { i18n } from '@lingui/core';
import { getBasisData, trackView } from '@services/itinerary';
import { dynamicActivate } from '@utils/i18n';

import { getEditParam, getItineraryIdentifier, shouldBeTracking, applyTheme, shouldbeBranded, getSliderImages, safeImageUrl } from '@utils/Utils';
import { evaluateTemplateSettings, evaluateCoverImageUrlFragmentsData, evaluateThemeData } from '@configs/evaluateTemplateSettings';
import './App.scss';
import './assets/fonts/wetu/wetu-font-icons.scss';

export const BasicData = createContext();
export const TemplateData = createContext();

function App() {
  const editParam = getEditParam();
  const [basicData, setBasicData] = useState(null);
  const [firstImageLoaded, setFirstImageLoaded] = useState(false);
  const [isInEditMode, setEditMode] = useState(editParam);
  const [templateData, setTemplateData] = useState(null);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin && event.origin.indexOf('wetu.com') !== -1) {
        switch (event.data.action) {
          case 'template-builder':
            const tc = JSON.parse(event.data.data.tc);
            const ot = JSON.parse(event.data.data.ot);
            const coverImageUrlFragments = JSON.parse(event.data.data.cover_image_url_fragments);

            setTemplateData(evaluateTemplateSettings(tc, basicData));

            if (basicData) {
              const previewThemeData = evaluateThemeData(ot, basicData);
              const previewCoverImageUrlData = evaluateCoverImageUrlFragmentsData(coverImageUrlFragments, ot, basicData);

              const data = {
                ...previewThemeData, 
                cover_image_url_fragments: previewCoverImageUrlData
              }
              
              setBasicData(data);

              if (shouldbeBranded()) {
                applyTheme(data?.theme, data?.branding)
              }
            }
            break
          default:
            console.log(`no flow catered for ${event.data.action}`)
        }
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [basicData])

  useEffect(() => {
    const editParam = getEditParam();
    setEditMode(editParam === true);

    const itineraryIdentifier = getItineraryIdentifier();

    if (itineraryIdentifier) {
      getBasisData(itineraryIdentifier, shouldbeBranded()).then(basicData => {

        setBasicData(basicData);
        setTemplateData(evaluateTemplateSettings(null, basicData));

        if (basicData.forbidden || basicData.disabled || basicData.not_found)
          return;

        document.title = basicData.name;

        dynamicActivate(basicData.language);

        if (shouldbeBranded()) {
          applyTheme(basicData?.theme, basicData?.branding)
        } else {
          applyTheme(basicData?.theme, null)
        }

        if (shouldBeTracking()) {
          trackView(itineraryIdentifier);
        }

        if (isInEditMode) {
          const accomodationImages = Object.values(basicData.accommodations).map((item) => item?.images?.[0] || null).filter(image => image !== null);
          const destinationImages = Object.values(basicData.destinations).map((item)=> item?.images?.[0] || null).filter(image => image !== null);

          const data = {
            'cover_image_url_fragments': basicData.cover_image_url_fragments,
            'accommodation_images': accomodationImages, 
            'destination_images': destinationImages
          }

          window.parent.postMessage({ action: 'iframe-ready', data }, '*');
        }
      })
    }
  }, [isInEditMode])

  const firstImage = useMemo(() => {
    if (basicData && !(basicData.media_type === "video" && basicData.video_embed_link)) {
      if (basicData.cover_image_url_fragments && basicData.cover_image_url_fragments.length > 0) {
        return getSliderImages(basicData.cover_image_url_fragments)[0];
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }, [basicData])

  if (!basicData) {
    return (
      <div className="app">
        <SpinnerComponent loadingScreen isInEditMode={isInEditMode} />
      </div>
    );
  } else {
    return (
      <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
        <BasicData.Provider value={basicData || {}}>
          <TemplateData.Provider value={templateData || {}}>
          {
            ((basicData.not_found || basicData.forbidden) && <ErrorPage />) ||
            (basicData.disabled && <CoverPage itineraryDisabled={true} />) ||
            <div className="app">
              <Helmet>
                <meta property="og:title" content="wetu.com" />
              </Helmet>
              {firstImage &&
                <img
                  style={{ display: "none" }}
                  fetchpriority="high"
                  alt=""
                  sizes="(max-width: 1920px) 100vw, 1920px"
                  onLoad={() => { setFirstImageLoaded(true); }}
                  onError={() => { setFirstImageLoaded(true); }}
                  src={safeImageUrl(firstImage.UrlFragment, firstImage.Default.Width, firstImage.Default.Height, firstImage.Default.Mode, firstImage.Default.QueryString)}
                  srcSet={`${firstImage.Alternates.map(x => `${safeImageUrl(firstImage.UrlFragment, x.Width, x.Height, x.Mode, x.QueryString)} ${x.Width}w`).join(', ')}, ${safeImageUrl(firstImage.UrlFragment, firstImage.Default.Width, firstImage.Default.Height, firstImage.Default.Mode, firstImage.Default.QueryString)} ${firstImage.Default.Width}w`}
                />
              }
              {firstImage && !firstImageLoaded ?
                <div className="app">
                  <SpinnerComponent loadingScreen isInEditMode={isInEditMode} />
                </div>
                :
                <>
                  <LayoutComponent template={templateData}></LayoutComponent>
                </>
              }
            </div>
          }
          </TemplateData.Provider>
        </BasicData.Provider>
      </I18nProvider>
    );
  }
}

export default App;
