import { logError } from '@services/log-service';

export const getBasisData = (itineraryIdentifier, includeBranding) => {
    return fetch(`${process.env.REACT_APP_API_DOMAIN}/API/Itinerary/Basic/v2/Get/${itineraryIdentifier}${!includeBranding ? "?includeBranding=false" : ""}`)
        .then(data => data.json())
        .catch((error) => logError(error, {
            location: "getBasisData"
        }));
}

export const getBodyData = async (itineraryIdentifier) => {
    return fetch(`${process.env.REACT_APP_API_DOMAIN}/API/Itinerary/Basic/v2/GetExtendedContent/${itineraryIdentifier}`)
        .then(data => data.json())
        .catch((error) => logError(error, {
            location: "getBodyData"
        }));
}

export const trackView = async (itineraryIdentifier) => {
    const isMobile = window.screen.availWidth <= 768;
    return fetch(`${process.env.REACT_APP_API_DOMAIN}/API/Itinerary/MetaData/TrackView/${itineraryIdentifier}/${isMobile}`, {
        method: 'POST',
        credentials: 'include'
    })
    .catch((error) => logError(error, {
        location: "trackView"
    }));
}

export const sendEnquiry = async (enquiryData, itineraryIdentifier, reCaptchaToken) => {
    return fetch(`${process.env.REACT_APP_API_DOMAIN}/API/MetaData/Enquire`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
            Id: itineraryIdentifier,
            Name: enquiryData.name,
            Email: enquiryData.email,
            Telephone: enquiryData.telephone,
            ContactPreference: enquiryData.contact_preference,
            ArrivalDate: enquiryData.arrival_date,
            DepartureDate: enquiryData.departure_date,
            Message: enquiryData.message,
            NumOfAdults: enquiryData.number_of_adults,
            NumOfChildren: enquiryData.number_of_children,
            ReCaptchaToken: reCaptchaToken
        })
    })
    .catch((error) => logError(error, {
        location: "sendEnquiry"
    }));
}