import React, { useContext } from 'react';
import { TemplateData} from '@App';
import { Trans } from '@lingui/macro';
import { scrollTo, isLite } from '@utils/Utils';
import { BasicData } from "@App";

import './style.scss';

const NavBarMenu = ({ setDropdownOpen }) => {
    const {ItineraryBlocks} = useContext(TemplateData)
    const basicData = useContext(BasicData);

    const toggleDropdown = () => {
        setDropdownOpen(prev => !prev);
    }

    const renderTripSummaryOption = (ItineraryBlocks) => {
        if(!ItineraryBlocks) 
            return (
                <li><h6 onClick={() => scrollTo('trip-summary-overview')}><Trans>Trip Summary</Trans></h6></li>
            );

        if(ItineraryBlocks?.length > 0 && 
            ItineraryBlocks.findIndex(x => x.Type === "TripSummary") > -1)
            return (
                <li><h6 onClick={() => scrollTo('trip-summary-overview')}><Trans>Trip Summary</Trans></h6></li>
            );

        return;
    }

    const renderItinerarySummaryOption = (ItineraryBlocks) => {
        if(!ItineraryBlocks)
            return (
                <li onClick={toggleDropdown} id="btn-toggle-dropdown">
                    <h6 style={{ pointerEvents: 'none' }}><Trans>Itinerary</Trans></h6>
                    <i style={{ pointerEvents: 'none' }} className='icon icon-chevron-down'></i>
                </li>
            ); 

        if(ItineraryBlocks?.length > 0 && 
            ItineraryBlocks.findIndex(x => x.Type === "DayByDaySummary") > -1)
            return (
                <li onClick={toggleDropdown} id="btn-toggle-dropdown">
                    <h6 style={{ pointerEvents: 'none' }}><Trans>Itinerary</Trans></h6>
                    <i style={{ pointerEvents: 'none' }} className='icon icon-chevron-down'></i>
                </li>
            );

        return;
    }

    return (
        <ul className={`nav-bar-menu ${isLite(basicData.operator_tier) ? 'navbar-lite' : ''}`}>
            <li><h6 onClick={() => scrollTo('home')}><Trans>Home</Trans></h6></li>
            {!isLite(basicData.operator_tier) && renderTripSummaryOption(ItineraryBlocks)}
            {!isLite(basicData.operator_tier) && renderItinerarySummaryOption(ItineraryBlocks)}
        </ul>
    )
}

export default React.memo(NavBarMenu);