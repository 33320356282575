import { i18n } from '@lingui/core';
import { en, es, af, ca, cs, da, de, fi, fr, it, nl, no, pl, pt, ru, sv, zh } from 'make-plural/plurals'

const locales = {
    af: "Afrikaans",
    ca: "Catalan",
    cs: "Czech",
    da: "Danish",
    de: "German",
    en: "English",
    es: "Spanish",
    fi: "Finnish",
    fr: "French",
    it: "Italian",
    nl: "Dutch",
    no: "Norwegian",
    pl: "Polish",
    pt: "Portuguese",
    ru: "Russian",
    sv: "Swedish",
    zh: "Chinese",
    'zh-TW': "Chinese (Traditional, Taiwan)"
};

i18n.loadLocaleData({
    af: { plurals: af },
    ca: { plurals: ca },
    cs: { plurals: cs },
    da: { plurals: da },
    de: { plurals: de },
    en: { plurals: en },
    es: { plurals: es },
    fi: { plurals: fi },
    fr: { plurals: fr },
    it: { plurals: it },
    nl: { plurals: nl },
    no: { plurals: no },
    pl: { plurals: pl },
    pt: { plurals: pt },
    ru: { plurals: ru },
    sv: { plurals: sv },
    zh: { plurals: zh },
    'zh-TW': { plurals: zh }
})

export const dynamicActivate = async (locale) => {
    const { messages } = await import(`@locales/${locales[locale] ? locale : 'en'}/messages`)

    i18n.load(locale, messages)
    i18n.activate(locale)
}