import { useCallback, useEffect, useRef } from 'react';
import './style.scss';

const SliderThumbs = ({ images, setActive, active = 0 }) => {
    const containerRef = useRef();

    const prev = useCallback(() => {
        setActive(prev => {
            if (prev === 0) {
                return images.length - 1;
            } else {
                return prev - 1;
            }
        });
    }, [setActive, images])
    
    const next = useCallback(() => {
        setActive(prev => {
            if (prev === images.length - 1) {
                return 0;
            } else {
                return prev + 1;
            }
        });
    }, [setActive, images])

    useEffect(() => {
        if (containerRef.current) {
            const activeElement = containerRef.current.children[active];
            const parentDims = containerRef.current.getBoundingClientRect();
            const activeDims = activeElement.getBoundingClientRect();
            if (activeDims.right > parentDims.right) {
                containerRef.current.scrollLeft = (activeDims.right - parentDims.right) + containerRef.current.scrollLeft;
            }
            
            if (activeDims.left < parentDims.left) {
                containerRef.current.scrollLeft = containerRef.current.scrollLeft - (parentDims.left - activeDims.left);
            }
        }
    }, [active, containerRef])

    return (
        <div className="slider-thumbs">
            <i className="icon-chevron-left" onClick={prev}></i>
            <div className="thumbs-container" ref={containerRef}>
                {images.map((image, i) => (
                    <div 
                        key={i} 
                        onClick={() => setActive(i)} 
                        className={`slider-thumb ${active === i ? 'active' : ''}`}
                    />
                ))}
            </div>
            <i className="icon-chevron-right" onClick={next}></i>
        </div>
    )
}

export default SliderThumbs;