import React, { useContext, useCallback, useMemo } from 'react';
import { Trans } from '@lingui/macro';
import { BasicData } from '@App';
import { getTextColorClass, scrollTo, isLite, getColor} from '@utils/Utils';

import './style.scss';

const NavBarBottom = ({ menuOpen, toggleMenu }) => {
    const { branding, unique_enquiry_url, theme, operator_tier } = useContext(BasicData);

    const colors = getColor(theme);

    const bookNow = useCallback(() => {
        if (unique_enquiry_url?.length || branding?.enquiry_url?.length) {
            unique_enquiry_url ?  window.open(unique_enquiry_url, '_blank') : window.open(branding.enquiry_url, '_blank')
        } else {
            scrollTo("enquiry-form");
        }
    }, [branding, unique_enquiry_url])

    const showEnquireButton = useMemo(() => {
        if (theme?.enquiry_button_label === "Hidden" || theme?.enquiry_button_label === "Hide" || !branding) return false;
        return true;
    }, [theme, branding])

    return (
        <div className={`nav-bar-bottom px-[30px] pr-md-[10px] pl-md-[20px] ${isLite(operator_tier) ? 'pr-[20px]' :'' }`} id='nav-bar-bottom'>
            {!isLite(operator_tier) &&
                <h6 className="btn-menu" onClick={toggleMenu}>
                    <i className={`icon-${menuOpen ? 'x' : 'mobile-menu'}`}></i>
                    <Trans>Menu</Trans>
                </h6>
            }
            {showEnquireButton && (
                <button className={`btn ${getTextColorClass(colors.button_color)} ${isLite(operator_tier) ? 'w-sm-100 ml-auto' : ''}`} onClick={bookNow}>
                    <h6><Trans id={theme?.enquiry_button_label || "Book Now"} values={theme?.enquiry_button_label || "Book Now"} /></h6>
                </button>
            )}
        </div>
    )
}

export default React.memo(NavBarBottom);