import { createRef } from 'react';
import { getYoutubeVideoId } from '@utils/Utils';
import YoutubeVideoComponent from './youtube-video';

import './style.scss';

const VideoComponent = ({ url, autoPlay, loop }) => {
    const wrapperRef = createRef();
    const uri = new URL(url)
    const isYoutube = uri.hostname.includes(`youtube`);

    return (
        <div className="video-container" ref={wrapperRef}>
            {isYoutube && <YoutubeVideoComponent id={getYoutubeVideoId(url)} wrapperRef={wrapperRef} autoPlay={autoPlay} loop={loop} />}
            {!isYoutube && <iframe title="video" className="non-youtube-video" src={url} allow="autoplay,fullscreen,picture-in-picture"></iframe>}
        </div>
    )
}

export default VideoComponent;