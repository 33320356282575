import { useState, useEffect } from "react";
import { debounce } from "@utils/Utils";

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(() => window.matchMedia("(max-width: 895px)").matches);

    useEffect(() => {
        const handleResize = debounce((e) => {
            setIsMobile(e.matches);
            document.querySelector('body').style.overflow = "auto";
        }, 300);

        const mediaQueryList = window.matchMedia("(max-width: 895px)");
        mediaQueryList.addEventListener('change', handleResize);

        return () => mediaQueryList.removeEventListener('change', handleResize);
    }, []);

    return isMobile;
};