import { configureStore } from '@reduxjs/toolkit';
import enquiryReducer from './slices/enquiry';
import mapReducer from './slices/map';
import bodyDataReducer from './slices/bodyDataSlice';

export const store = configureStore({
    reducer: {
        enquiry: enquiryReducer,
        map: mapReducer,
        bodyData: bodyDataReducer
    },
})