import './style.scss';

const SectionContainer = ({ sticky, children, ...props }) => {
    return (
        <section {...props}>
            {children}
        </section>
    )
}

export default SectionContainer;