import { Trans } from "@lingui/macro";
import { useMemo } from "react";

const NavBarDropDownDailyInfo = ({ id, stopIndex, active, navigateTo }) => {
    const isActive = useMemo(() => {
        return active === `daily-info-${id}${stopIndex >= 0 ? `-${stopIndex}` : ''}`;
    }, [id, stopIndex, active]);

    return useMemo(() => (
        <div onClick={navigateTo(`daily-info-${id}${stopIndex >= 0 ? `-${stopIndex}` : ''}`)} className={`nav-bar-dropdown-leg-section daily-info-section${isActive ? ' active' : ''}`}>            
            <div className="nav-bar-dropdown-leg-section-heading">
                <div className="circle" />
                <p><Trans>Explore</Trans></p>
            </div>
            <div className="nav-bar-dropdown-leg-section-item">
                <p><Trans>Day by Day</Trans></p>
            </div>
        </div>
    ), [id, stopIndex, isActive, navigateTo])
}

export default NavBarDropDownDailyInfo;