import React from 'react';
import { logError } from '@services/log-service';

class GlobalErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
      // Update state so the next render shows the fallback UI
      return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
      // Log the error details
      logError(error, {
        componentStack: errorInfo.componentStack
      });
    }

    render() {
      // Note: Add a fallback UI here using the hasError (When designed.)
      return this.props.children; 
    }
  }

  export default GlobalErrorBoundary;