import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player/youtube';

const YoutubeVideoComponent = ({ id, wrapperRef, autoPlay, loop }) => {
    const [playing, setPlaying] = useState(autoPlay);

    useEffect(() => {
        if (wrapperRef.current) {
            const observer = new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting && autoPlay) {
                    setPlaying(true);
                } else {
                    setPlaying(false); 
                }
            }, {
                threshold: [0, 1]
            })
            observer.observe(wrapperRef.current);
            return () => observer.disconnect();
        }
    }, [wrapperRef, autoPlay])

    return (
        <ReactPlayer 
            url={`https://www.youtube.com/watch?v=${id}&rel=0`}
            playing={playing}
            volume={0}
            loop={loop}
            muted={true}
            width="100%"
            height="100%"
            playsinline
        />
    )
}

export default YoutubeVideoComponent;