import { isReadable } from "@ctrl/tinycolor";
import { i18n } from "@lingui/core";
import { midpoint, bearing, lineArc, destination, distance, lineDistance } from '@turf/turf'
import { toWgs84, toMercator } from '@turf/projection';
import { TinyColor } from "@ctrl/tinycolor";


export const safeImageUrl = (fragment, width = 1920, height = 1080, mode = 'n', queryString = "?fmt=jpg") => {
    return encodeURI(`${process.env.REACT_APP_RESOURCE_DOMAIN}/imageHandler/${mode}${width}x${height}/${fragment}${queryString}`);
}

export const safeResourceUrl = (fragment) => {
    return encodeURI(`${process.env.REACT_APP_RESOURCE_DOMAIN}/Resources${fragment}`);
}

export const getItineraryIdentifier = () => {
    const arr = window.location.pathname.split('/');

    return arr[arr.length - 1];
}

export const getEditParam = () => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const result = params.get("edit");

    return result === "true";
}

export const shouldBeTracking = () => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const m = params.get("m");

    if (m && m.includes("d")) {
        return false;
    }

    return true;
}

export const shouldbeBranded = () => {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const m = params.get("m");

    if (m && m.includes("b")) {
        return false;
    }

    return true;
}

export const checkBrandingType = (theme, type) => {
    if ((type === "Personal" && (["Smart", "Consultant"].includes(theme?.contact_details_setting)))) {
        return "consultant";
    } else {
        return "company";
    }
}

export const getSliderImages = (fragments = []) => {
    return fragments.map(x => ({
        UrlFragment: typeof x === "string" ? x : x.url_fragment ? x.url_fragment : x.url,
        imageDesc: x.description ? x.description : null,
        imageLabel: x.label ? x.label : null,
        imageCredit: x.credit ? x.credit : null,
        Default: { Width: 1920, Height: 1080, Mode: "c" },
        Alternates: [
            { Width: 200, Height: 113 },
            { Width: 1090, Height: 613 },
            { Width: 1471, Height: 827 },
            { Width: 1883, Height: 1059 },
            { Width: 1420, Height: 799 },
            { Width: 1509, Height: 849 },
            { Width: 1712, Height: 963 }
        ]
    }))
}

export const getLetters = (index) => {
    const letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    return letters[index] ? letters[index].toUpperCase() : (index - (letters.length - 1)).toString()
};

export const getDateDiff = (date1, date2, units) => {
    const diffInTime = new Date(date2).getTime() - new Date(date1).getTime();
    if (units === 'days') {
        return diffInTime / (1000 * 3600 * 24);
    } else {
        return diffInTime;
    }
}

export const getFormattedDate = (startDate, endDate, startDay, endDay) => {
    return startDate ? `${startDate}${endDate && endDate !== startDate ? ` - ${endDate}` : ""}` : startDay ? `${i18n._("Day")} ${startDay}${endDay && endDay !== startDay ? ` - ${endDay}` : ""}` : "";
}

export const getTextColorClass = (color) => {
    if (color) {
        color = color.includes("#") ? color : `#${color}`;
        
        if (color.length === 9 && color.slice(-2).toUpperCase() === "FF") {
            color = color.slice(0, -2);
        }
        
        return isReadable(color, '#fff') ||  color === "#6CB741" || color === "#6cb741" ? "light-text" : "dark-text"; //Primary green isn't seen as dark so needs an override for default theme
    }
}

export const getYoutubeVideoId = (url) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return match[7];
}

export const addClickableLinks = (text = '') => {
    const regExp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
    return text.replace(regExp, function(url) {
      return '<a href="' + url + '">' + url + '</a>';
    })
}

export const formatNumber = (num) => {
    return num < 10 ? `0${num}` : num;
}

export const scrollTo = (id) => {
    if (id) {
        const element = document.getElementById(id);
        let topOffset = 0;
        const topBar = document.getElementById("nav-bar-top");
        if (topBar && ["trip-summary-overview", "enquiry-form"].includes(id)) {
            topOffset = topBar.clientHeight;
        }
        
        const yOffset = window.scrollY < element.getBoundingClientRect().top + window.scrollY ? -topOffset : -(topOffset + document.getElementById("nav-bar-bottom").clientHeight);
        const y = element.getBoundingClientRect().top + window.scrollY + yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });
    }
    else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
}

export const applyTheme = (theme, branding) => {
    const favicon = document.querySelector("link[rel~='icon']");

    theme?.primary_color && theme.primary_color !== 'BDC3C7' && document.documentElement.style.setProperty('--primary-color', `#${theme?.primary_color}`);
    theme?.secondary_color && theme.secondary_color !== 'BDC3C7' && document.documentElement.style.setProperty('--secondary-color', `#${theme?.secondary_color}`);
    theme?.accent_color && theme.accent_color !== '95A5A6' && document.documentElement.style.setProperty('--accent-color', `#${theme?.accent_color}`);
    theme?.button_color && theme.button_color !== '95A5A6' && document.documentElement.style.setProperty('--button-color', `#${theme?.button_color}`);
    theme?.headings_font && document.documentElement.style.setProperty('--headings-font', `${theme?.headings_font}`);
    theme?.body_font && document.documentElement.style.setProperty('--body-font', `${theme?.body_font}`);
    theme?.hyperlink_color && document.documentElement.style.setProperty('--hyperlink-color', `#${theme?.hyperlink_color}`)
    theme?.link_underline ? document.documentElement.style.setProperty('--hyperlink-decoration', 'underline') : document.documentElement.style.setProperty('--hyperlink-decoration', 'none')

    favicon.href = branding?.itinerary_domain === window.location.host && branding?.custom_favicon_url ? branding.custom_favicon_url : `${process.env.REACT_APP_RESOURCE_DOMAIN}/Resources/Operators/1/favicon.png`;

    if (theme?.headings_font && theme?.body_font) {
        if (theme?.headings_font === theme?.body_font) {
            let url = "https://fonts.googleapis.com/css?family=";
            url += theme?.headings_font.replace(" ", "+");
            url += ":wght@400;500;600&display=swap";
            let link = document.createElement('link')
            link.href = url;
            link.rel = "stylesheet";
            link.type = "text/css";
            document.head.appendChild(link);
        } else {
            let hfUrl = "https://fonts.googleapis.com/css?family=";
            hfUrl += theme?.headings_font.replace(" ", "+");
            hfUrl += ":wght@400;500;600&display=swap";
            let hfLink = document.createElement('link')
            hfLink.href = hfUrl;
            hfLink.rel = "stylesheet";
            hfLink.type = "text/css";
            document.head.appendChild(hfLink);

            let bfUrl = "https://fonts.googleapis.com/css?family=";
            bfUrl += theme?.body_font.replace(" ", "+");
            bfUrl += ":wght@400;500;600&display=swap";
            let bfLink = document.createElement('link')
            bfLink.href = bfUrl;
            bfLink.rel = "stylesheet";
            bfLink.type = "text/css";
            document.head.appendChild(bfLink);
        }
    }
}

export const debounce = (func, time = 100) => {
    let timer;
    return (event) => {
        if (timer) clearTimeout(timer);
        timer = setTimeout(func, time, event);
    }
}

export const elementMounted = (id, callback) => {
    if (!document.getElementById(id)) {
        return
    }
    const observer = new MutationObserver(() => {
        const el = document.getElementById(id);
        if (el) {
            observer.disconnect();
            callback(el);
        }
    })
    observer.observe(document.getElementById(id), {
        childList: true
    })
}

export const getColor = (theme) => {
    return {
        primary: theme?.primary_color ? `${theme.primary_color}` : 'BDC3C7',
        secondary: theme?.secondary_color ? `${theme.secondary_color}` : 'BDC3C7',
        accent: theme?.accent_color ? `${theme.accent_color}` : '95A5A6',
        hyperlink: theme?.hyperlink_color ? `${theme.hyperlink_color}` : theme?.accent_color || '95A5A6',
        navigation_bar_color: theme?.navigation_bar_color ? `${theme.navigation_bar_color}` : 'BDC3C7',
        button_color: theme?.button_color ? `${theme.button_color}` : '95A5A6',

    }
}

export const validatePhonenumber = (val) => {
    return /^\d+$/.test(val);
}

export const validateEmail = (value) => {
    return String(value)
        .toLowerCase()
        .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
}

export const isLite = (tier) => { 
    return tier === 'Lite';
}

//INTERACTIVE MAP UTILS
export const createCurvedRoute = (route) => {
    if (route) {
        const start = [
            parseFloat(route.start_content_entity_location[1]),
            parseFloat(route.start_content_entity_location[0])
        ];

        const end = [
            parseFloat(route.end_content_entity_location[1]),
            parseFloat(route.end_content_entity_location[0]),
        ];

        let routeFeature = {
            type: 'LineString',
            coordinates: [start, end]
        };
        routeFeature = toWgs84(routeFeature);
        const lineD = lineDistance(routeFeature, { units: 'kilometers' });
        const mp = midpoint(routeFeature.coordinates[0], routeFeature.coordinates[1]);
        const center = destination(
            mp,
            lineD,
            bearing(routeFeature.coordinates[0], routeFeature.coordinates[1]) - 90
        );
        const lA = lineArc(
            center,
            distance(center, routeFeature.coordinates[0]),
            bearing(center, routeFeature.coordinates[1]),
            bearing(center, routeFeature.coordinates[0]),
            { steps: 256 }
        );

        const projection = toMercator(lA);
        const routeMarkerPoint = projection.geometry.coordinates[Math.floor(projection.geometry.coordinates?.length / 2)];

        return {
            points: projection.geometry.coordinates,
            midPoint: routeMarkerPoint,
            bearing: bearing(routeFeature.coordinates[0], routeFeature.coordinates[1])
        };

    }
}


export const getVariantIcon = (variant) => {
    switch (variant) {
        case "train":
            return 'icon-train';
        case "tram":
            return 'icon-tram';
        case "charterFlight_Helicopter":
            return 'icon-helicopter-flight';
        case "charterFlight_Plane":
            return 'icon-charter-flight-north';
        case "charterFlight_Seaplane":
            return 'icon-seaplane';
        case "hiking":
            return 'icon-hike';
        case "horse":
            return 'icon-horseback';
        case "cycle":
        case "selfDrive_Cycling":
        case "transfer_Cycling":
            return 'icon-cycling';
        case "selfDrive_4x4":
        case "transfer_4x4":
            return "icon-vehicle-4x4";
        case "selfDrive_4x4_enclosed":
        case "transfer_4x4_enclosed":
            return "icon-vehicle-4x4-enclosed";
        case "selfDrive_Car":
        case "transfer_Car":
            return "icon-car";
        case "selfDrive_MotorBike":
            return "icon-motorbike";
        case "rv":
            return "icon-rv";
        case "scheduledFlight":
        case "scheduledflight_plane":
            return 'icon-plane-north';
        case "boat_Canoe":
            return 'icon-canoe';
        case "boat_CruiseBoat":
            return "icon-cruise";
        case "boat_ferry":
            return "icon-ferry";
        case "boat_houseBoat":
            return "icon-houseboat";
        case "boat_speedboat":
            return "icon-speedboat";
        case "transfer_Bus":
            return "icon-bus";
        case "transfer_walk":
            return "icon-walking";
        default:
            return "icon-car";
    }
}


export const getIcon = (mode, variant) => {
    if (variant) {
        return getVariantIcon(variant)
    } else {
        switch (mode) {
            case "ScheduledFlight":
                return 'icon-plane-north';
            case "CharterFlight":
                return 'icon-charter-flight-north';
            case "Selfdrive":
            case "SelfdriveNoRoute":
                return 'icon-car';
            case "Transfer":
                return 'icon-bus';
            case "Boat":
            case "BoatNoRoute":
                return 'icon-cruise';
            case "Train":
            case "TrainNoRoute":
                return 'icon-train';
            case "Hike":
            case "HikeNoRoute":
                return 'icon-walking';
            default:
                return 'icon-car';
        }
    }
}


export const getRouteColor = (mode) => {
    switch (mode) {
        case "ScheduledFlight":
            return '#C72927';
        case "CharterFlight":
            return '#0F8843';
        case "Selfdrive":
        case "SelfdriveNoRoute":
            return '#064789';
        case "Transfer":
            return '#F49321';
        case "Boat":
        case "BoatNoRoute":
            return '#E5C247';
        case "Train":
        case "TrainNoRoute":
            return '#0AB6B4';
        case "Hike":
        case "HikeNoRoute":
            return '#0090FF';
        case "NoTransit":
        case "Placeholder":
            return '#A857A1';
        default:
            return "#fff";
    }
}


export const getLightenedColor = (mode) => {
    const color = getRouteColor(mode);
    return new TinyColor(color).tint(60).toHexString();
}


export const getLayerStyles = (markerIndex, color) => {

    return [
        {
            id: `line-route-${markerIndex}`,
            type: 'line',
            paint: {
                'line-color': color,
                'line-width': 4,
            },
            filter: ['==', '$type', 'LineString']
        }, {
            id: `line-point-layer-${markerIndex}`,
            type: 'circle',
            paint: {
                'circle-radius': 6,
                'circle-color': color,
            },
            filter: ['==', '$type', 'Point']
        }
    ]
}


export const getLayerData = (coords) => {
    return {
        type: 'FeatureCollection',
        features: [{
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'LineString',
                coordinates: coords
            }
        },
        {
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'Point',
                coordinates: coords[0]
            }
        },
        {
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'Point',
                coordinates: coords[coords.length - 1]
            }
        }]
    }
}


export const getMarkerStyle = (color, mobileRouteColor, legStop = false) => ({
    borderRadius: '50% 50% 0 50%',
    height: legStop ? '30px' : '40px',
    width: legStop ? '30px' : '40px',
    marginLeft: legStop ? '-14px' : '-18px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: mobileRouteColor?.mode ? getRouteColor(mobileRouteColor.mode) : color
});


export const routeMarkerStyle = (marker) => {
    return {
        backgroundColor: getRouteColor(marker?.mode),
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}



