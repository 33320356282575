import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { BasicData } from "@App";
import { getFormattedDate, scrollTo, elementMounted } from "@utils/Utils";
import NavBarDropDownDestination from "./destination";
import NavBarDropDownAccommodation from "./accommodation";
import NavBarDropDownDailyInfo from "./daily-info";

import './style.scss';

const NavBarDropdown = ({ open, setOpen, setMenuOpen }) => {
    const ref = useRef();
    const [active, setActive] = useState();
    const { trip_summary } = useContext(BasicData);

    const destinationLegs = useMemo(() => trip_summary?.legs?.filter(x => x.destination_id || x.accommodation_id || x.stops || x.type === "OwnArrangement") || [], [trip_summary]);

    const navigateTo = useCallback((id) => () => {
        setOpen(false);
        setMenuOpen(false);
        scrollTo(id);
        setActive(id);
    }, [setOpen, setMenuOpen])

    const observeElement = useCallback((el) => {
        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setActive(el.id);
            }
        }, {
            rootMargin: '-45% 0% -45% 0%'
        })
        observer.observe(el);
        return () => observer.disconnect();
    }, [])

    useEffect(() => {
        if (destinationLegs.length > 0) {
            destinationLegs.forEach(leg => {
                if (leg.destination_id) {
                    elementMounted(`destination-${leg.id}-${leg.destination_id}`, observeElement);
                    leg.accommodation_id && elementMounted(`accommodation-${leg.id}-${leg.accommodation_id}`, observeElement);
                    elementMounted(`daily-info-${leg.id}`, observeElement);
                } else if (leg.stops) {
                    leg.stops.forEach((stop, j) => {
                        elementMounted(`destination-${leg.id}-${stop.destination_id}-${j}`, observeElement);                       
                        leg.accommodation_id && elementMounted(`accommodation-${leg.id}-${leg.accommodation_id}`, observeElement);
                        elementMounted(`daily-info-${leg.id}-${j}`, observeElement);
                    })
                } else {
                    elementMounted(`accommodation-${leg.id}-${leg.accommodation_id}`, observeElement);
                    elementMounted(`daily-info-${leg.id}`, observeElement);
                }
            })
        }
    }, [destinationLegs, observeElement])

    useEffect(() => {
        if (!open) {
            return
        }
        const handleClick = (e) => {
            const el = document.getElementById('btn-toggle-dropdown');
            if (e.target !== el && !ref.current.contains(e.target)) {
                setOpen(false);
            }
        } 
        document.addEventListener('click', handleClick);
        return () => document.removeEventListener('click', handleClick);
    }, [open, setOpen])

    return (
        <div className={`nav-bar-dropdown${open ? ' open' : ''}`} id="nav-bar-dropdown" ref={ref}>
            {destinationLegs.map((leg, i) => {
                if (leg.destination_id) {
                    return (
                        <div className="nav-bar-dropdown-leg" key={i}>
                            <h5 className="days">{getFormattedDate(null, null, leg.start_day, leg.end_day)}</h5>
                            <NavBarDropDownDestination id={leg.id} destination_id={leg.destination_id} active={active} navigateTo={navigateTo} />
                            {leg.accommodation_id && <NavBarDropDownAccommodation id={leg.id} accommodation_id={leg.accommodation_id} alternate_accommodations={leg.alternate_accommodations} active={active} navigateTo={navigateTo} />}
                            <NavBarDropDownDailyInfo id={leg.id} active={active} navigateTo={navigateTo} />
                        </div>
                    )
                } else if (leg.stops) {
                    return (
                        <div className="nav-bar-dropdown-leg" key={i}>
                            <h5 className="days">{getFormattedDate(null, null, leg.start_day, leg.end_day)}</h5>
                            {leg.stops.map((stop, stopIndex) => {
                                return (
                                    <React.Fragment key={stopIndex}>
                                        <NavBarDropDownDestination id={leg.id} destination_id={stop.destination_id} stopIndex={stopIndex} active={active} navigateTo={navigateTo} />
                                        {stopIndex === 0 && leg.accommodation_id && <NavBarDropDownAccommodation id={leg.id} accommodation_id={leg.accommodation_id} active={active} navigateTo={navigateTo} />}
                                        <NavBarDropDownDailyInfo id={leg.id} stopIndex={stopIndex} active={active} navigateTo={navigateTo} />
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    )
                } else {
                    return (
                        <div className="nav-bar-dropdown-leg" key={i}>
                            <h5 className="days">{getFormattedDate(null, null, leg.start_day, leg.end_day)}</h5>
                            {leg.type !== "OwnArrangement" && <NavBarDropDownAccommodation id={leg.id} accommodation_id={leg.accommodation_id} active={active} navigateTo={navigateTo} /> }
                            <NavBarDropDownDailyInfo id={leg.id} active={active} navigateTo={navigateTo} />
                        </div>
                    )
                }
            })}
        </div>
    )
}

export default React.memo(NavBarDropdown);