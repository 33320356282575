import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    mapMarkers: {},
}

export const mapSlice = createSlice({
    name: 'map',
    initialState,
    reducers: {
        setMapMarkers: (state, action) => {
            state.mapMarkers = action.payload;
        }
    }
})

export const { setMapMarkers} = mapSlice.actions;
export default mapSlice.reducer;