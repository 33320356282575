import { appInsights } from '../appInsights';

export const logEvent = async (eventName, customProperties = {}) => {
    appInsights.trackEvent({
      name: eventName,
      properties: customProperties,
    });
}

export const logError = async (errorMessage, customProperties = {}) => {
    appInsights.trackException({
      exception: new Error(errorMessage), // Create an Error object
      severityLevel: 3, // Adjust severity level (3 = Error, 2 = Warning, etc.)
      properties: customProperties, // Add any additional custom data
    });
}