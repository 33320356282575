import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    value: {
        name: '',
        email: '',
        telephone: '',
        contact_preference: 'email',
        number_of_adults: '',
        number_of_children: '',
        arrival_date: "",
        departure_date: "",
        message: ""
    }
}
export const enquirySlice = createSlice({
    name: 'enquiry',
    initialState,
    reducers: {
        setEnquiryValue: (state, action) => {
            state.value = action.payload;
        },
    }
})
export const { setEnquiryValue } = enquirySlice.actions;
export default enquirySlice.reducer;