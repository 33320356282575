
import { enterpriseItineraryBlocks } from '@configs/layoutConfigs';
import { isLite } from '@utils/Utils'; 

// Evaluate itinerary settings/output on page load
export const evaluateTemplateSettings = (parsedTemplateSettings, basicData) => {
    if (isLite(basicData?.operator_tier)) {
      const firstDestination = basicData.trip_summary.legs.find(leg => leg.hasOwnProperty('destination_id'));

      if (firstDestination) {
        basicData.cover_image_url_fragments = [basicData.destinations[firstDestination.destination_id].images[0]];
      }

      basicData.theme.button_color = '6CB741'
      basicData.theme.primary_color = '666471'
      basicData.theme.secondary_color = '666471'
      basicData.theme.accent_color = '6CB741'
      basicData.theme.navigation_bar_color = 'FFFFFF'

    }

    if (parsedTemplateSettings) {
      return parsedTemplateSettings;
    }

    if (basicData.theme?.template_settings) {
      const templateSettings = basicData.theme.template_settings;

      if (templateSettings.itinerary_blocks)
        return {
          ItineraryBlocks: templateSettings.itinerary_blocks.map(x => {
            return {
              Type: x.type,
              StackRankOrder: x.stack_rank_order
            }
          }),
          IsContactFABVisible: templateSettings.is_contact_f_a_b_visible
        };
    }

    return {
      IsContactFABVisible: true,
      ItineraryBlocks: enterpriseItineraryBlocks,
    };
  }

// Evaluate itinerary settings/output on iframe message event listener
export const evaluateCoverImageUrlFragmentsData = (parsedCoverImageUrlFragmentsData, parsedThemeData, basicData) => {
  const result = { ...basicData};
  result.cover_image_url_fragments = [ ...parsedCoverImageUrlFragmentsData ];

  if (parsedCoverImageUrlFragmentsData.length !== 0) {
    result.cover_image_url_fragments = [];
    parsedCoverImageUrlFragmentsData?.forEach((image_url) => result.cover_image_url_fragments.push(image_url))
  }    

  if (parsedThemeData.cover_image !== "" && result.cover_image_url_fragments.findIndex(x => x === parsedThemeData.cover_image) === -1) {
    result.cover_image_url_fragments.unshift(parsedThemeData.cover_image);
  }

  return result.cover_image_url_fragments;
}

export const evaluateThemeData = (parsedThemeData, basicData) => {
  const result = { ...basicData };
  result.theme = { ...parsedThemeData };

  if (parsedThemeData.display_slideshow !== basicData.theme.display_slideshow) {
    result.theme.display_slideshow = parsedThemeData.display_slideshow;
  }

  return result;
}

