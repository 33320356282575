import './style.scss';
import React, { useContext } from 'react';
import { BasicData } from '@App';
import NoAccessLogo from '@assets/images/wetu-no-access-icon.svg';
import DeleteLogo from '@assets/images/wetu-delete-icon.svg';

const ErrorPage = () => {
    const basicData = useContext(BasicData);
    let headerLogo;
    let header;
    let body;

    if (basicData.forbidden) {
        headerLogo = NoAccessLogo;
        header = `<label>Sorry you don't have access to this itinerary output.</label>`;
    } else {
        headerLogo = DeleteLogo;
        header = `<label>This itinerary has been deleted.</label>`;
        body = `<label>Please contact the creator of the itinerary for more information.</label>`;
    }

    return (
        <>
            <div className='container'>
                <img src={headerLogo} alt="" />
                <div className='header-text'>
                    <span dangerouslySetInnerHTML={{ __html: header }}></span>
                </div>
                <div>
                    <span dangerouslySetInnerHTML={{ __html: body }}></span>
                </div>
            </div>
        </>
    )
}

export default ErrorPage;