import { useContext, useMemo } from "react";
import { BasicData } from '@App';
import { Trans } from "@lingui/macro";

const NavBarDropDownAccommodation = ({ id, accommodation_id, alternate_accommodations, active, navigateTo }) => {
    const { accommodations } = useContext(BasicData);

    const isActive = useMemo(() => {
        return active === `accommodation-${id}-${accommodation_id}`;
    }, [id, accommodation_id, active]);

    return useMemo(() => (
        <div onClick={navigateTo(`accommodation-${id}-${accommodation_id}`)} className={`nav-bar-dropdown-leg-section accommodation-section${isActive ? ' active' : ''}`}>
            <div className="nav-bar-dropdown-leg-section-heading">
                <div className="circle" />
                <p><Trans>Accommodation</Trans></p>
            </div>
            <div className="nav-bar-dropdown-leg-section-item">
                <p>{accommodations[accommodation_id].name}</p>
            </div>
            <div className="nav-bar-dropdown-leg-section-item alternate-accommodation">
                {alternate_accommodations?.map((alternate, j) => (
                    <p className="alternate-accommodation" key={j}>/ {accommodations[alternate].name}</p>
                ))}
            </div>
        </div>
    ), [id, accommodation_id, alternate_accommodations, isActive, navigateTo, accommodations])
}

export default NavBarDropDownAccommodation;