import LottiePlayer from 'react-lottie-player';
import loadingAnimation from '@assets/json/enquire-loading.json';

import './style.scss';

const SpinnerComponent = ({ loadingScreen, isInEditMode }) => {
    return (
        <div className="spinner-wrap">
            {loadingScreen && !isInEditMode ? (
                <LottiePlayer 
                    animationData={loadingAnimation}
                    play
                    className="lottie-spinner"
                />
            ) : (
                <div className="spinner"></div>
            )}
        </div>
    )
}

export default SpinnerComponent